import React from 'react';
import { Card, CardContent, Typography, IconButton, CardActions } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function PersonaCard({ persona, handleChatOpen, handleEditPersona, handleRemovePersona }) {
    const truncateText = (text, length) => {
        if (text.length <= length) {
            return text;
        }
        return text.slice(0, length) + '...';
    };

    return (
        <Card variant="outlined" onClick={() => handleChatOpen(persona)} sx={{ height: '220px', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: 1 }}>
                <Typography variant="h5" component="div">
                    {truncateText(persona.title, 50) || 'No Title'}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    CreatedBy: {persona.createdBy}
                </Typography>
                {/* Additional content can be included here, if necessary */}
            </CardContent>
            <CardActions disableSpacing sx={{ justifyContent: 'flex-end', marginTop: 'auto', p: '0.5rem' }}>
                <IconButton aria-label="edit" onClick={(event) => { event.stopPropagation(); handleEditPersona(persona.id); }}>
                    <EditIcon />
                </IconButton>
                <IconButton aria-label="delete" onClick={(event) => { event.stopPropagation(); handleRemovePersona(persona.id); }}>
                    <DeleteIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
}

export default PersonaCard;