// PersonalInfo.js

import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Your Firebase config import path
import { CircularProgress, Typography, Box, Paper, Container, Button, Divider } from '@mui/material';
import PaymentInfoList from './PaymentInfoList';



function PersonalInfo() {
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(0);
  const userId = auth.currentUser?.uid
  //   console.log(userId)
  // Handler function to activate account
  const handleActivateAccount = async (status) => {
    const paymentRef = doc(collection(db, 'payment'), userId);
    // Replace 'userId' with the actual UID of the user's account
    // Replace defaultPaymentInfo with the appropriate fields and values
    const defaultPaymentInfo = {
      tier: 'basic', // This should match your desired default tier
      amount: 0,        // Default amount for initial activation
      status: status,     // The account status you prefer, true if it's active
    };
    try {
      await setDoc(paymentRef, defaultPaymentInfo);
      console.log('Account activated successfully!');
      setChange(prev=>prev+1)
      // Perform additional actions after activation if necessary
    } catch (error) {
      console.error('Error activating account:', error);
    }
  };


  useEffect(() => {
    setLoading(true);
    const fetchPaymentInfo = async () => {
      try {
        const paymentRef = doc(db, 'payment', userId);
        const paymentSnapshot = await getDoc(paymentRef);

        if (paymentSnapshot.exists()) {
          setPaymentInfo(paymentSnapshot.data());
        } else {
          // Handle the case where the payment doc doesn't exist
          console.log('No payment document found');
        }
      } catch (error) {
        // Handle any errors during data fetching
        console.error('Error fetching payment information:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentInfo();
  }, [userId, change]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (!paymentInfo) {
    return (
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
        <Typography variant="subtitle1">No payment information available.</Typography>
        <Button variant="contained" color="primary" onClick={()=> handleActivateAccount(false)}>
          Activate Account
        </Button>
      </Paper>
    )
  }

  return (
    <>
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
        <Typography variant="h6" gutterBottom>
          Payment Information
        </Typography>
        <Typography variant="body1">
          <strong>Tier:</strong> {paymentInfo.tier}
        </Typography>
        <Typography variant="body1">
          <strong>Amount:</strong> {paymentInfo.amount}
        </Typography>
        <Typography variant="body1">
          <strong>Status:</strong> {paymentInfo.status ? "Active" : "Inactive" }
        </Typography>
        {/* <Button variant="contained" color="primary" onClick={()=> handleActivateAccount(true)}>
          Activate Account
        </Button> */}
        {/* You can include more information fields here */}
      {paymentInfo.tier == "superuser" && (
        <>
        <Divider />
        <Typography variant="h6" gutterBottom>
          Grant permissions
        </Typography>
        {/* List all users and the payment tier and amount and status from firebase collection "payment" and add functionality to change the information  */}
        <PaymentInfoList />
        
        </>
        )
      }
      </Paper>
    </>
  );
}

export default PersonalInfo;