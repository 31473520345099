import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Your Firebase configuration import path
import { collection, getDocs, query, updateDoc, doc } from 'firebase/firestore';
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, Switch } from '@mui/material';



function PaymentInfoList() {
  const [paymentData, setPaymentData] = useState([]);
  const [editIndex, setEditIndex] = useState(-1); // Tracks the index of the item being edited
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      try {
        const paymentQuery = query(collection(db, 'payment'));
        const querySnapshot = await getDocs(paymentQuery);
        const payments = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPaymentData(payments);
      } catch (error) {
        console.error('Error fetching payments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const handleEditStart = (index) => {
    setEditIndex(index);
  };

  const handleEditChange = (e, field, index) => {
    const value = field === 'status' ? e.target.checked : e.target.value;
    const updatedPayments = [...paymentData];
    updatedPayments[index][field] = value;
    setPaymentData(updatedPayments);
  };

  const handleSave = async (id, index) => {
    const updatedRecord = paymentData[index];
    const paymentRef = doc(db, 'payment', id);
    try {
      await updateDoc(paymentRef, {
        tier: updatedRecord.tier,
        status: updatedRecord.status,
      });
      setEditIndex(-1); // Reset edit index
    } catch (error) {
      console.error('Error updating payment record:', error);
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>User ID</TableCell>
          <TableCell>Tier</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {paymentData.map((record, index) => (
            <TableRow key={record.id}>
                <TableCell>{record.id}</TableCell>
                <TableCell>
                    {editIndex === index ? (
                        <TextField value={record.tier} onChange={(e) => handleEditChange(e, 'tier', index)} />
                    ) : (
                        record.tier
                    )}
                </TableCell>
                <TableCell>
                    {editIndex === index ? (
                        <Switch
                            checked={record.status}
                            onChange={(e) => handleEditChange(e, 'status', index)}
                            color="primary"
                        />
                    ) : (
                        record.status ? 'Active' : 'Inactive'
                    )}
                </TableCell>
                <TableCell>
                    {editIndex === index ? (
                        <Button variant="contained" color="primary" onClick={() => handleSave(record.id, index)}>
                            Save
                        </Button>
                    ) : (
                        <Button variant="outlined" onClick={() => handleEditStart(index)}>
                            Edit
                        </Button>
                    )}
                </TableCell>
            </TableRow>
        ))}
          </TableBody>
    </Table>
  );
}

export default PaymentInfoList;