import React, { useEffect, useState, useRef } from 'react';
import { Box, Paper, Container, Grid, Card, CardContent, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,  List, ListItem, ListItemText, IconButton, CardActions } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material/';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import { auth, db, rdb } from '../firebase'; // Adjust this import according to your Firebase configuration file path
import { collection, getDoc, getDocs, addDoc, doc, setDoc, deleteDoc, where, query, updateDoc } from 'firebase/firestore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import OpenAI from "openai";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  materialDark,
  solarizedlight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import PersonaEditDialog from './PersonaEditDialog';
import PersonaCard from './PersonaCard';
import MessageList from './MessageList';
import { onAuthStateChanged } from 'firebase/auth';

const openai = new OpenAI({"apiKey":process.env.REACT_APP_OPENAIAPIKEY, dangerouslyAllowBrowser: true});

const renderers = {
    table: ({ node, ...props }) => <table className="modern-table" {...props} />,
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          language={match[1].trim() == "" ? "coffee" : match[1]}
          PreTag="div"
          {...props}
          style={materialDark}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code {...props}>{children}</code>
      );
    },
  };




function GPT() {
    const [gptPersonas, setGptPersonas] = useState([]);
    const [open, setOpen] = useState(false);
    const [newPersona, setNewPersona] = useState({ description: '' });
    const [titleReady, setTitleReady] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [updated, setUpdated] = useState(0)
    const [chatopen, setChatopen] = useState(false)
    const [newRoomName, setNewRoomName] = useState('');
    const [chatRooms, setChatRooms] = useState([]); // Replace with state management or fetching from DB
    const [currentPersona, setCurrentPersona] = useState("");
    const [openConversation, setOpenConversation] = useState(false)
    const [currentChatroomId, setCurrentChatroomId] = useState(null)
    const [currentThread, setCurrentThread] = useState({})
    const [currentThreadList, setCurrentThreadList] = useState(null)
    const [openAILoading, setOpenAILoading] = useState(false)
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [editingPersona, setEditingPersona] = useState(null);
    const endOfMessagesRef = useRef(null);

    const handleSaveEdit = async () => {
        if (!editingPersona) return;
        let updates = {
            ...editingPersona,
            updatedAt: new Date(),
        };
        const assistantDelResponse = await openai.beta.assistants.del(editingPersona.assistant.id);
        console.log(assistantDelResponse)
        const assistant = await openai.beta.assistants.create({
            name: "Bot",
            instructions: `${editingPersona.description}`,
            tools: [],
            model: "gpt-4-1106-preview"
            });
        updates = { ...updates, assistant:assistant}
        
        
        // Update in database
        const personaRef = doc(db, 'personas', editingPersona.id);
        await setDoc(personaRef, updates);
    
        // Update in local state
        setGptPersonas(prevPersonas =>
          prevPersonas.map(p => (p.id === editingPersona.id ? updates : p))
        );
    
        setIsEditDialogOpen(false); // Close the dialog
    };

    
    
    useEffect(()=> {
        console.log("move", currentThreadList)

        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' })
    },
        [currentChatroomId]
    )


    const fetchData = async () => {
        const personaQuery = query(
            collection(db, 'personas'), 
            where("createdBy", "==", auth.currentUser.uid)
        );
        
        const querySnapshot = await getDocs(personaQuery);
        
        // const querySnapshot = await getDocs(collection(db, 'personas', where("createdBy","==",auth.currentUser.uid)));
        const personasData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setGptPersonas(personasData);
    };
    const fetchChatRooms = async () => {
        const querySnapshot = await getDocs(collection(db, "chatrooms"))
        const chatRooms = querySnapshot.docs.map(doc => ({id:doc.id, ...doc.data()}))
        setChatRooms(chatRooms)
    }

    useEffect(() => {
        const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, now you can fetch the data
                fetchData();
                fetchChatRooms();
            } else {
                // User is signed out
                // Handle user being signed out if necessary
            }
        });

        { titleReady && saveDoc() }
        return () => unsubscribeAuth();

    }, [titleReady, updated, currentPersona, currentChatroomId,currentThreadList]);
    

    const handleCreatePersona = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setCurrentChatroomId(null)
        setOpen(false);
        setNewPersona({ description: '' }); // Reset form
    };
    const handleChatClose = () => {
        setOpenConversation(false)
        setCurrentChatroomId(null)
        setCurrentChatroomId(null)
        setChatopen(false);
        setCurrentThreadList(null);
        
        // setNewPersona({ description: '' }); // Reset form
    };

    const saveDoc = async () => {

        const assistant = await openai.beta.assistants.create({
            name: "Bot",
            instructions: `${newPersona.description}`,
            tools: [],
            model: "gpt-4-1106-preview"
          });
        
        const createdAt = new Date();
        // console.log(newPersona)
        const personaData = {
            description: newPersona.description,
            title: newPersona.title.choices[0].text,
            createdAt: createdAt,
            updatedAt: createdAt,
            createdBy: auth.currentUser ? auth.currentUser.uid : 'anonymous',
            assistant : assistant,
        };
        await addDoc(collection(db, 'personas'), personaData);
        setUpdating(false)
        setTitleReady(false)
        handleClose();
    }

    const handleSave = async () => {
        setUpdating(true)
        await generateTitle(newPersona.description);
        // Optionally, refresh or update your persona list here
    };

    const handleChange = (event) => {
        setNewPersona({ ...newPersona, description: event.target.value });
    };

    const generateTitle = async (description) => {
        let title = await openai.completions.create({
            model: 'gpt-3.5-turbo-instruct',
            prompt: `Write a short title for following description : \n\n ${description}`
        });
        setNewPersona({ ...newPersona, title });
        setTitleReady(true)
    }

    //   const generateImage = async () => {
    //     const response = await openai.createImage({
    //         model: "dall-e-3",
    //         prompt: "a white siamese cat",
    //         n: 1,
    //         size: "1024x1024",
    //     });
    //     image_url = response.data.data[0].url;
    //   }
    const handleCreateRoom = async () => {
        // console.log('Attempting to create room:', newRoomName);
        const thread = await openai.beta.threads.create({metadata : {userId : auth.currentUser.uid}});
        // console.log(thread)
        // Create a new document reference in 'chatrooms' collection with a specific document ID
        const chatRoomsColRef = collection(db, 'chatrooms');

        try {
            await addDoc(chatRoomsColRef, {
                name: newRoomName,
                userId: auth.currentUser.uid, // Storing the user's UID
                persona: currentPersona,
                thread : thread
            });
            // console.log('Room created successfully');
            setNewRoomName("")
            setUpdated(updated+1)
            // setChatopen(false)
            // Additional logic after successful creation (e.g., clearing input, updating UI)
        } catch (error) {
            console.error('Error creating new room:', error);
        }
    };
    
    const handleRemoveRoom = async (document) => {

        const roomRef = doc(db, 'chatrooms', document.id);
        console.log(document.thread.id)
        const response = await openai.beta.threads.del(document.thread.id);
        console.log("delete_thread:",response);
    
        try {
            await deleteDoc(roomRef);
            setUpdated(updated+1)
            // console.log(`Room ${roomId} removed successfully`);
            // Additional logic to update UI or state
        } catch (error) {
            console.error(`Error removing room ${document.id}:`, error);
        }
    };
    const handleChatOpen = (persona) => {
        setChatopen(true)
        setCurrentPersona(persona)

    }
    const handleRoomSelection = (room) => {
        // Logic when a room is selected
        // console.log('Room Selected:', room);
        // Handle room selection, e.g., open the chat for this room
    };
    const handleEditPersona = (personaId) => {
        // Find the persona with personaId
        const personaToEdit = gptPersonas.find(p => p.id === personaId);
        if (!personaToEdit) return; // Persona not found, handle the error as preferred.
    
        setEditingPersona(personaToEdit); // Set the persona in state
        setIsEditDialogOpen(true); // Open the dialog
    };

    const handleRemovePersona = async (personaId) => {
        // Confirm deletion with the user
        const confirm = window.confirm("Are you sure you want to delete this persona and all associated data?");
        if (!confirm) {
            return; // If the user cancels, exit the function.
        }
    
        // Reference to the document in the 'personas' collection
        const personaRef = doc(db, 'personas', personaId);
    
        try {
            // Retrieve the persona to get the assistant.id
            const personaSnapshot = await getDoc(personaRef);
            const persona = personaSnapshot.data();
    
            // Delete the assistant associated with the persona if it exists
            if (persona && persona.assistant && persona.assistant.id) {
                let deleteAssistantResponse = await openai.beta.assistants.del(persona.assistant.id);
                // console.log(deleteAssistantResponse)
            }
    
            // Query for threads associated with the persona
            const threadsQuery = query(collection(db, 'chatrooms'), where('persona.id', '==', personaId));
            const threadsSnapshot = await getDocs(threadsQuery);
    
            // Delete each thread document
            const threadDeletions = threadsSnapshot.docs.map(async (threadDoc) => {
                // You might also want to remove associated data like messages here
                let deleteDocResponse = await deleteDoc(doc(db, 'threads', threadDoc.id));
    
                // If OpenAI has threads associated, you would delete them using the API
                if (threadDoc.data().thread) {
                    let threadDelResponse = await openai.beta.threads.del(threadDoc.data().thread.id);
                    // console.log(threadDelResponse)
                }
            });
    
            // Wait for all thread deletions to complete
            await Promise.all(threadDeletions);
    
            // Delete the persona document itself
            await deleteDoc(personaRef);
    
            // Update local state array to exclude the removed persona
            setGptPersonas(prevPersonas => prevPersonas.filter(p => p.id !== personaId));
    
            // Persona and its threads are deleted, show a confirmation message
            console.log(`Persona with ID: ${personaId} and all associated threads have been deleted.`);
    
        } catch (error) {
            // Handle any errors that occur during deletion
            console.error(`Error removing persona with ID: ${personaId} and associated threads:`, error);
            // Consider showing an error message to the user
        }
    };

    const handleClickOnChatroom = async (chatroomId, thread) =>{
        setOpenConversation(true)
        setCurrentChatroomId(chatroomId)
        console.log("handleClickOnChatRoom",thread)
        setCurrentThread(thread)
        setOpenAILoading(true)
        let threadList = await openai.beta.threads.messages.list(thread.id, {limit: 40});
        let runs = await openai.beta.threads.runs.list(
            thread.id
          );
        console.log("runs",runs)
        console.log(threadList);
        setCurrentThreadList(threadList);
        setOpenAILoading(false);
        
    }

    
    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} mt={1}>
                {gptPersonas.map((persona,ix) => (
                    <Grid item xs={4}> 
                        <PersonaCard key={"pc"+ix} persona={persona} handleChatOpen={handleChatOpen} handleEditPersona={handleEditPersona} handleRemovePersona={handleRemovePersona} />
                    </Grid>
                ))}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" startIcon={<AddCircleOutline />} onClick={handleCreatePersona}>
                        Create New Persona
                    </Button>
                </Grid>
            </Grid>

            {/* Dialog for chatting with selected persona */}
            <Dialog
                open={chatopen}
                onClose={handleChatClose}
                PaperProps={{
                    style: {
                        minWidth: '800px'
                    }
                }}
            >
                <DialogTitle>ChatRoom</DialogTitle>
                {!openConversation && 
                (
                        <>
                            <DialogContent>
                                {/* Chat Room List */}
                                <List>
                                    {chatRooms.filter(doc => doc.persona.id == currentPersona.id).map(doc => (
                                        <ListItem
                                            key={doc.id}
                                            secondaryAction={
                                                <IconButton edge="end" onClick={() => handleRemoveRoom(doc)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        >
                                            <ListItemText primary={doc.name} onClick={() => { handleClickOnChatroom(doc.id, doc.thread) }} />
                                        </ListItem>
                                    ))}
                                </List>
                                <TextField
                                    label="New Room Name"
                                    fullWidth
                                    value={newRoomName}
                                    onChange={(e) => setNewRoomName(e.target.value)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCreateRoom}>Create Room</Button>
                                <Button onClick={handleChatClose}>Close</Button>
                            </DialogActions>
                        </>
                    )
                }

                
                <DialogContent>

                    {/* Placeholder for Real-time Chat UI */}
                    <p>Room name : {currentChatroomId}</p>
                    
                    {openConversation && (
                        <MessageList currentThread={currentThread} setOpenAILoading={setOpenAILoading} openAILoading={openAILoading} currentPersona={currentPersona} />
                        
                    )
                    }
                </DialogContent>
            </Dialog>

            {/* Dialog for creating new persona */}
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '500px' // Set a minimum width for the dialog
                    }
                }}
            >
                <DialogTitle>Create New Persona</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Description"
                        type="text"
                        fullWidth
                        multiline
                        rows={5}
                        variant="standard"
                        value={newPersona.description}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    {updating ?
                        <CircularProgress />
                        :
                        <>

                            <Button onClick={handleClose}>Cancel</Button>
                            <Button onClick={handleSave}>Save</Button>
                        </>
                    }
                </DialogActions>
            </Dialog>

            {isEditDialogOpen && (
                <PersonaEditDialog
                    open={isEditDialogOpen}
                    handleClose={setIsEditDialogOpen}
                    handleSave={handleSaveEdit}
                    editingPersona={editingPersona}
                    setEditingPersona={setEditingPersona}
                />
            )}
        </Container>
    );
}

export default GPT;
