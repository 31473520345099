import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { functions } from '../firebase'; 
import { httpsCallable } from 'firebase/functions'

const LOGIN_SERVER = 'https://accounts.gnu.ac.kr/common/login/login.do/'
const ENPASS_SERVER = 'https://accounts.gnu.ac.kr/enpass/';
const SERVICE_KEY = 'MVI3ZzdWeggqcQmTkALH';
const SERVER_NAME = 'deevo-org2gpt.web.app';
const APP_ID = 'org2gpt';


// 확인할일, response가 어떻게 돌아오는지, 안돌아오면 functions 에서 무슨일이 일어나고 있는지를 로그를 통해 확인 필요 
// server_name 에서 https 를 뺏더니 redirect에서 바로 승인이 되어서 나옴. 그래서 redirect에서 axios로 바로 파라메터를 가져와야한다. validate는 안해도 될듯. 
const validateTicket = async (ticket) => {
    const validateEnpassTicket = httpsCallable(functions, 'validateEnpassTicket');
  
    try {
      // Call the Firebase Function
      const result = await validateEnpassTicket({ epTicket: ticket });
  
      if (result.data.success) {
        // Handle successful validation
        console.log('Ticket is valid:', result.data.response);
      } else {
        // Handle failed validation
        console.log('Ticket is not valid:', result.data.message, result.data.response);
      }
    } catch (error) {
      // Handle errors when calling the function
      console.error('Error calling validateEnpassTicket:', error);
    }
  };



const LoginSSO = () => {
  const [loggedIn, setLoggedIn] = useState(false);
//   const [searchParams] = useSearchParams();

  useEffect(() => {
    const checkLogin = async () => {
      
      const ticket = new URLSearchParams(window.location.search).get('epTicket');
      console.log("ticket",ticket)
    //   console.log(searchParams)
    //   const epTicket = searchParams.get('epTicket');
    //   console.log(epTicket)
      
      if (!ticket) {
        redirectToEnpass();
        return;
      }
      console.log("here")
      validateTicket(ticket)
    //   try {
    //     console.log("ticket",ticket)
    //     const response = await axios.get(`${ENPASS_SERVER}simpleValidate`, {
    //       params: {
    //         epTicket: ticket,
    //         appId: APP_ID,
    //         service: `${SERVER_NAME}${window.location.pathname}`
    //       },
    //       headers: {
    //         'serviceKey': SERVICE_KEY
    //       }
    //     });
    //     console.log(response)
    //     if (response.data.authenticationSuccess) {
    //       // 성공적으로 로그인 상태를 확인했을 때
    //       setLoggedIn(true);
    //       // 추가 처리, 예를 들어 사용자 정보 저장 등
    //     } else {
    //       // 로그인 상태가 아니라면 리디렉션
    //       redirectToEnpass();
    //     }
    //   } catch (error) {
    //     console.error('로그인 상태 확인 중 오류 발생', error);
    //     // redirectToEnpass();
    //   }
    };

    checkLogin();
  }, []);

  const redirectToEnpass = async () => {
    console.log("redirectToEnpass")
    // const serviceUrl = `${SERVER_NAME}${window.location.pathname}`;
    const serviceUrl = SERVER_NAME
    // window.location.href = `${ENPASS_SERVER}?appId=${APP_ID}&service=${serviceUrl}`;
    const response = await axios.get(`${ENPASS_SERVER}?appId=${APP_ID}&service=${serviceUrl}`)
    console.log(response)
  };

  if(loggedIn){
    // 로그인 상태일 때 렌더할 컴포넌트
    return <div>로그인 되었습니다.</div>;
  } else {
    // 로그인 상태가 아닐 때 로딩 또는 대체 컴포넌트를 렌더할 수 있습니다.
    return <div>로그인 상태를 확인 중입니다...</div>;
  }
};

export default LoginSSO;