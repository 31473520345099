import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Modal, Paper } from '@mui/material';
import { auth, createUser, signIn, db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';


// Define these components outside of the Login component

const EmailAndPasswordFields = ({ email, setEmail, password, setPassword }) => (
    <>
        <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="dense"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="dense"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
        />
    </>
);
const LoginForm = ({ email, setEmail, password, setPassword, handleLogin }) => (
    <form onSubmit={(e) => { e.preventDefault(); handleLogin(); }}>
        <EmailAndPasswordFields email={email} setEmail={setEmail} password={password} setPassword={setPassword} />
        <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
        >
            Log In
        </Button>
    </form>
);

const RegistrationForm = ({ email, setEmail, password, setPassword, confirmPassword, setConfirmPassword, handleRegister }) => (
    <form onSubmit={(e) => { e.preventDefault(); handleRegister(); }}>
        <EmailAndPasswordFields email={email} setEmail={setEmail} password={password} setPassword={setPassword} />
        <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="dense"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
        >
            Sign Up
        </Button>
    </form>
);



function Login({handleClose}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();
    const handleLogin = async () => {
        try {
            await signIn(auth, email, password);
            clearAndCloseModal();
            navigate('/');
            // Implement redirect or other success handling here
        } catch (error) {
            setErrorMessage("Error during login: " + error.message);
        }
    };

    const isValidPassword = (password) => {
        const minLength = 8;
        const hasNumber = /\d/;
        const hasUpperCase = /[A-Z]/;
        return (
            password.length >= minLength &&
            hasNumber.test(password) &&
            hasUpperCase.test(password)
        );
    };

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }
        if (!isValidPassword(password)) {
            setErrorMessage("Password does not meet the requirements. It must be at least 8 characters long, contain a number and an uppercase letter.");
            return;
        }
        try {
            const userCredential = await createUser(auth, email, password);
            const defaultPaymentInfo = {
                tier: 'basic', // set the default tier for new users
                amount: 0,     // default amount
                status: false  // default status (e.g., false for not paid)
            };
            // Adding payment information to Firestore
            await setDoc(doc(db, 'payment', userCredential.user.uid), defaultPaymentInfo);
            clearAndCloseModal();
            navigate('/');
            // Implement redirect or other success handling here
        } catch (error) {
            setErrorMessage("Error during registration: " + error.message);
        }
    };

    const handleOpenModal = (loginMode) => {
        setIsLogin(loginMode);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    // Clears form fields and closes modal
    const clearAndCloseModal = () => {
        console.log("closeModal")
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setErrorMessage('');
        setOpenModal(false);
        handleClose();
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', p: 3 }}>
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
            <Typography variant="h6" sx={{ mb: 2 }}>
                Welcome
            </Typography>
            <Button
                variant="contained"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => handleOpenModal(true)}
            >
                Log In
            </Button>
            <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{ mt: 2 }}
                onClick={() => handleOpenModal(false)}
            >
                Join
            </Button>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Paper sx={modalStyle}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {isLogin ? "Log In" : "Join"}
                    </Typography>
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                    {isLogin ? 
                        <LoginForm 
                            email={email} 
                            setEmail={setEmail} 
                            password={password} 
                            setPassword={setPassword} 
                            handleLogin={handleLogin} 
                        /> 
                        : 
                        <RegistrationForm 
                            email={email} 
                            setEmail={setEmail} 
                            password={password} 
                            setPassword={setPassword} 
                            confirmPassword={confirmPassword} 
                            setConfirmPassword={setConfirmPassword} 
                            handleRegister={handleRegister} 
                        />
                    }
                </Paper>
            </Modal>
        </Box>
    );
}

export default Login;
