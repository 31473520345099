import React from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';

const PersonaEditDialog = ({ open, handleClose, handleSave, editingPersona, setEditingPersona }) => {
    // Handle field change
    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setEditingPersona(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Prepare to save the changes
    const onSave = () => {
        handleSave(editingPersona); // Call the save handler with the editingPersona state
    };
    return (
      <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle>Edit Persona</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Title"
                    type="text"
                    fullWidth
                    variant="standard"
                    name="title"
                    value={editingPersona.title}
                    onChange={handleFieldChange}
                />
                <TextField
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="standard"
                    name="description"
                    value={editingPersona.description}
                    onChange={handleFieldChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)}>Cancel</Button>
                <Button onClick={onSave}>Save</Button>
            </DialogActions>
      </Dialog>
    );
  };

export default PersonaEditDialog;