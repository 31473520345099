// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from 'firebase/auth'; // for authentication
import { getFirestore } from 'firebase/firestore'; // for Firestore database
import { getDatabase } from 'firebase/database'; // for Firebase Realtime Database
import { getStorage } from 'firebase/storage'; // for Firebase Storage
import { getFunctions } from 'firebase/functions';




// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAjH1MnQZXpdYY98Vdsyd44UmytPEWcopA",
  authDomain: "deevo-gnu2gpt.firebaseapp.com",
  projectId: "deevo-gnu2gpt",
  storageBucket: "deevo-gnu2gpt.appspot.com",
  messagingSenderId: "3588330908",
  appId: "1:3588330908:web:374fa591a4db0bb196e459",
  measurementId: "G-5S9YXHNH0P"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const rdb = getDatabase(app);
export const auth = getAuth(app); // Export the auth instance
export const createUser = createUserWithEmailAndPassword; // Export the createUser function
export const signIn = signInWithEmailAndPassword; // Export the signIn function
export const onAuthStateChangedVar = onAuthStateChanged; // Export the onAuthStateChanged function
export const signOutVar = signOut; // Export the signOut function
export const storage = getStorage(app);
export const functions = getFunctions(app);