import React from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';

function MainPage() {
    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Welcome to Our ChatGPT Clone for Organizations
                    </Typography>
                    <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                        Our application is a state-of-the-art language model designed specifically for organizational needs. 
                        It offers features like advanced text generation, natural language understanding, and customization options to suit the unique requirements of different business environments. 
                        Whether it's generating reports, automating responses, or providing insights, our ChatGPT clone is equipped to handle various textual tasks with efficiency and accuracy.
                        Embrace the power of AI to enhance your organizational workflows and communication.
                    </Typography>
                </Box>
            </Paper>
        </Container>
    );
}

export default MainPage;
