import React, { useRef, useEffect, useState } from 'react';
import { Box, Paper, TextField, Button, LinearProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  materialDark,
  solarizedlight,
} from "react-syntax-highlighter/dist/esm/styles/prism";

import OpenAI from "openai";

const openai = new OpenAI({"apiKey":process.env.REACT_APP_OPENAIAPIKEY, dangerouslyAllowBrowser: true});

const renderers = {
    table: ({ node, ...props }) => <table className="modern-table" {...props} />,
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          language={match[1].trim() == "" ? "coffee" : match[1]}
          PreTag="div"
          {...props}
          style={materialDark}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      ) : (
        <code {...props}>{children}</code>
      );
    },
  };

function MessageList({ currentThread, openAILoading, setOpenAILoading, currentPersona }) {
    const endOfMessagesRef = useRef(null);
    const endOfScrollRef = useRef(null);
    const [currentThreadList, setCurrentThreadList] = useState(null)
    const [newMessage, setNewMessage] = useState("")
    const [copiedIndex, setCopiedIndex] = useState(null);
    const [initLoaded, setInitLoaded] = useState(false)

    useEffect(() => {
        if (initLoaded && endOfScrollRef.current) {
            endOfScrollRef.current.scrollIntoView({ behavior: 'smooth' });
          }

        const handle = async() => {
            setOpenAILoading(true)
            let threadList = await openai.beta.threads.messages.list(currentThread.id, {limit:40});
            let runs = await openai.beta.threads.runs.list(
                currentThread.id
              );
            // console.log("runs",runs)
            // console.log(threadList);
            setCurrentThreadList(threadList);
            setOpenAILoading(false);
            setInitLoaded(true)
        }
        handle();
    }, [initLoaded]);
    const copyToClipboard = async (text,index) => {

        await navigator.clipboard.writeText(text);
        setCopiedIndex(index)
        
    };
    const sendMessage = async() => {
        if (newMessage.trim() !== "") {
            await runGPT(newMessage);
        }
    };

    const runGPT = async (msg) => {
        setOpenAILoading(true)
        setNewMessage("");
        try {
            // Creating a message
            const messageResponse = await openai.beta.threads.messages.create(
                currentThread.id,
                {
                    role: "user",
                    content: msg
                }
            );    
            // Creating a run
            const runResponse = await openai.beta.threads.runs.create(
                currentThread.id,
                { 
                    assistant_id: currentPersona.assistant.id
                }
            );
            // console.log('Run Response:', runResponse);

            // Poll for the run status
            let statusResponse = await openai.beta.threads.messages.list(currentThread.id);
            setCurrentThreadList(statusResponse)
            let maxDelay = 32000;
            let delay = 2000; // Starting delay time in milliseconds
            let runStatus;

            while (true) {
                // Wait before checking the run status
                await new Promise(resolve => setTimeout(resolve, delay));

                // Retrieve the run status
                runStatus = await openai.beta.threads.runs.retrieve(currentThread.id, runResponse.id);
                // console.log("runStatus", runStatus);

                // Break the loop if the status is 'completed' or any other status indicating completion
                if (runStatus.status === 'completed') {
                    break;
                }

                // Optionally handle errors or other statuses here...

                // Calculate the next delay time, ensuring it does not exceed the maximum delay
                delay = Math.min(delay * 2, maxDelay);
            }

            // If the status is 'completed', handle the completion logic
            if (runStatus.status === "completed") {
                statusResponse = await openai.beta.threads.messages.list(currentThread.id);
                setCurrentThreadList(statusResponse);
                setOpenAILoading(false);
            }
            
        } catch (error) {
            console.error('Error in runGPT:', error);
        }
    };


    return (
        <>
            <Box fullWidth sx={{ margin: 'auto' }}>
                {/* {console.log("openConv",currentThreadList)} */}
                {currentThreadList && (
                    [...currentThreadList.data] // Create a shallow copy of the array
                        .reverse().map((message, index, arr) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: message.role === "user" ? 'flex-end' : 'flex-start',
                                    mb: 1,
                                }}
                            >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        maxWidth: '80%',
                                        padding: '10px',
                                        backgroundColor: message.role === "user" ? '#DCF8C6' : '#ECEFF1',
                                        borderRadius: '20px',
                                    }}
                                >
                                    <div>
                                        {message?.content && (
                                            <button onClick={() => copyToClipboard(message.content[0].text.value, index)}>
                                                {copiedIndex == index ? "Copied" : "Copy"}
                                            </button>
                                        )}
                                        <ReactMarkdown
                                            components={renderers}
                                            remarkPlugins={[remarkGfm]}
                                        >
                                            {message?.content[0].text.value}
                                        </ReactMarkdown>
                                    </div>
                                </Paper>
                                
                                {/* {index === arr.length - 1 && <div ref={endOfMessagesRef} />} Reference attached to the last bubble */}
                            </Box>
                        )))}
                {openAILoading && <LinearProgress />}
            </Box>

            <TextField
                value={newMessage}
                multiline
                rows={5}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message"
                fullWidth
            />
            <Button onClick={sendMessage}>Send</Button>
            <div ref={endOfScrollRef} />

        </>
    );
}

export default MessageList;