import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Modal, Box, Button } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import Login from './Login';
import { auth, db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

function TopMenu() {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true)
  const [paymentInfo, setPaymentInfo] = useState(null);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
      fetchPaymentInfo(currentUser);
      if (!currentUser) {
        setPaymentInfo(null)
        // console.log("payment null")
      }
    });
    const fetchPaymentInfo = async (user) => {
      try {
        const paymentRef = doc(db, 'payment', user?.uid);
        const paymentSnapshot = await getDoc(paymentRef);

        if (paymentSnapshot.exists()) {
          // console.log("setpayment")
          setPaymentInfo(paymentSnapshot.data());
        } else {
          // Handle the case where the payment doc doesn't exist
          console.log('No payment document found');
        }
      } catch (error) {
        // Handle any errors during data fetching
        console.error('Error fetching payment information:', error);
      } finally {
        setLoading(false);
      }
    };
    
    return () => unsubscribe();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLogout = () => auth.signOut();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Org2GPT
          </Typography>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', marginRight: '20px' }}>Home</Link>
          {user && paymentInfo?.status ? (
            <Link to="/gpt" style={{ textDecoration: 'none', color: 'inherit', marginRight: '20px' }}>GPT</Link>
          ) : 
          (<></>)}
          {/* <Link to="/gpt-model-2" style={{ textDecoration: 'none', color: 'inherit', marginRight: '20px' }}>GPT Model 2</Link> */}
          {/* Add more navigation links as needed */}
          {/* {console.log(user,paymentInfo)} */}
          {user ? (
            <React.Fragment>
              <Typography sx={{ marginRight: 2 }}>
                <Link to="/personal-info" style={{ textDecoration: 'none', color: 'inherit' }}>
                  {user.email}
                </Link>
              </Typography>
              <IconButton color="inherit" onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </React.Fragment>
          ) : (
            <IconButton color="inherit" onClick={handleOpen}>
              <LoginIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
      >
        <Box sx={style}>
          <Login handleClose={handleClose} />
        </Box>
      </Modal>
    </div>
  );
}

export default TopMenu;
