import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TopMenu from './components/TopMenuV1';
import MainPage from './components/MainPage';
import GPT from './components/GPT'; // Import components for different GPT models
import LoginSSO from './components/LoginSSO';
import PersonalInfo from './components/PersonalInfo';

// import GPTModel2 from './components/GPTModel2';
import { CssBaseline, Container } from '@mui/material';
// Import additional components you might have, like a Login component

function App() {
  return (
    <Router>
      <CssBaseline /> {/* Normalize CSS across browsers */}
      <TopMenu />
      <Container>
        <Routes>
          <Route path="/" element={<MainPage />} /> {/* Home/Main Page */}
          <Route path="/gpt" element={<GPT />} />
          <Route path="/loginsso" element={<LoginSSO />} />
          <Route path="/personal-info" element={<PersonalInfo />} />

          {/* Define other routes as needed, for example: */}
          {/* <Route path="/login" element={<Login />} /> */}
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
